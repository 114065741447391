@import './fonts.scss';

$chocolate : #7c4a2c;// hsl(22, 52%, 18%);
$dark-chocolate: #3c2010;
$bg-brown: #61371e;
$yellow : #ffc13b;
$light-yellow : #fecd86;
$grey : #656565;
$white : #ffffff;

//fonts
$heading-font : "Champion";
$body-font : "MyriadPro";