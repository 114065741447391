@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .page-heading{
        h1{
            font-family: $heading-font;
            color: #fff;
            text-align: center;
            padding: 60px 0;
            font-size: 50px;
            text-transform: uppercase;
        }
    }

    .page-body{
        text-align: center;
        padding-bottom: 40px;
        h2 {
            font-family: $heading-font;
            color: #fff;
            padding: 0 0 30px;
            margin-top: 40px;
            font-size: 30px;
            text-transform: uppercase;
        }
        h2:first-of-type {
            margin-top: -30px;
        }
        p {
            padding-bottom: 30px;
            padding: 0 60px 30px;
        }
        .video-frame {
            width: 100%;
            padding-bottom: 56%;
            position: relative;

            iframe {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
            margin-bottom: 30px;
        }
        .winner {
            max-width: 100%;
        }
    }
}