@font-face {
     font-family: "Knockout";
     src: url("../fonts/Knockout-HTF29-JuniorLiteweight.woff2") format("woff2"),
          url("../fonts/Knockout-HTF29-JuniorLiteweight.woff") format("woff");
     font-weight: normal;
}

@font-face {
    font-family: "Champion";
    src: url("../fonts/champion_htfwelterweight_regular-webfont.woff2") format("woff2"),
         url("../fonts/champion_htfwelterweight_regular-webfont.woff") format("woff");
    font-weight: normal;
}

@font-face {
     font-family: "Champion";
     src: url("../fonts/champion-htf-middleweight-webfont.woff2") format("woff2"),
          url("../fonts/champion-htf-middleweight-webfont.woff") format("woff");
     font-weight: 500;
}

@font-face {
  font-family: "Champion";
  src: url("../fonts/champion_htfheavyweight_regular-webfont.woff2") format("woff2"),
       url("../fonts/champion_htfheavyweight_regular-webfont.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "MyriadPro";
  src: url("../fonts/MyriadPro-Regular.eot") format("eot"),
        url("../fonts/MyriadPro-Regular.svg") format("svg"),
        url("../fonts/MyriadPro-Regular.ttf") format("ttf"),
        url("../fonts/MyriadPro-Regular.woff") format("woff");
}