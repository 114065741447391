@import '../variables';

.main-wrapper{
    position: relative;
    z-index: 1;

    // background: url(../../images/coming-soon-bg.jpg);

    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    //min-height: calc(100vh - 186px); // 100 height - (header + footer)

    @media screen and (max-width: 460px) {

        // background: url(../../images/coming-soon-bg-mobile.jpg);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 90px;
    }
    _:-ms-fullscreen, :root .full-Vh { height: calc(100vh - 300px); } // IE 11?

    .full-Vh{
        min-height: calc(100vh - 65px); // 100 height - (header + footer + disclaimer)
        
        display: flex;
        align-items: center;
    }
    .row {
        .heading-text {
            .unlock-the-secret, .chance-to-win, .plus-cash {
                font-family: 'Knockout';
                font-weight: normal;
            }
        }
        .home-text-container{ 
            color: #fff;
            margin: 60px 0;

            @media screen and (max-width:460px) {
                margin-top: 50px;
            }

            .heading-text {
                text-transform: uppercase;
                font-size: 50px;
                font-family: $heading-font;
                text-stroke: 1px $chocolate;
                -webkit-text-stroke: 1px $chocolate;
                // text-shadow: -4px -4px 0 $chocolate, 4px -4px 0 $chocolate, -4px 4px 0 $chocolate, 4px 4px 0 $chocolate;
                text-align: center;
                line-height: 1;

                @media screen and (max-width: 460px) {
                    font-size: 30px;
                    // text-shadow: -2px -2px 0 #7c4a2c, 2px -2px 0 #7c4a2c, -2px 2px 0 #7c4a2c, 2px 2px 0 #7c4a2c
                }

                .unlock-the-secret {
                    font-size: 40px;
                    line-height: 1.2;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 30px;
                    }
                }

                .chance-to-win {
                    font-size: 40px;
                    line-height: 1.2;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 30px;
                    }

                    em{
                        font-style: italic;
                    }
                }

                .unlock-win{
                    font-size: 40px;
                    line-height: 1.2;
                    font-weight: 500;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }
                }

                .prize-money {
                    font-size: 120px;
                    line-height: 1.1;
                    text-stroke: 3px $chocolate;
                    -webkit-text-stroke: 3px $chocolate;

                    sup {
                        font-size: .5em;
                        vertical-align: .8em;
                        top: 0;
                    }
                    small {
                        font-size: .7em;
                    }

                    @media screen and (max-width: 1199px) {
                        font-size: 102px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 80px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 70px;
                    }

                    

                    + .prize-money {
                        font-size: 80px;
                        @media screen and (max-width: 1199px) {
                            font-size: 72px;
                        }
    
                        @media screen and (max-width: 991px) {
                            font-size: 64px;
                        }
    
                        @media screen and (max-width: 460px) {
                            font-size: 60px;
                        }
                    }
                }

                
                .plus-cash {
                    font-size: 55px;
                    line-height: 1.2;

                    @media screen and (max-width: 1199px) {
                        font-size: 44px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 35px;
                    }
                }
            }

            .home-header {
                font-family: $heading-font;
                font-size: 34px;
                text-align: center;
                margin: 30px 0;
                color: $yellow;
                text-transform: uppercase;
                line-height: 1.2;

                @media screen and (max-width: 460px) {
                    font-size: 25px;
                }
            }

            .home-body-text{
                font-family: $body-font;
                font-size: 24px;
                text-align: center;
                margin: 15px 0;

                a { color: $yellow; }

                @media screen and (max-width: 991px) {
                    font-size: 18px;
                }
            }

            .learn-more-container {
                font-family: $body-font;
                font-size: 24px;
                text-align: center;
                margin: 15px 0;

                background-image: url(../../images/chevron.png);
                background-repeat: no-repeat;
                background-position: bottom center;
                padding-bottom: 40px;

                a, a:visited, a:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .insta-link {
                width: 2em;
                height: 2em;
            }
            
        }
        &#howtoenter {
            text-align: center;

            h2, h3 {
                text-transform: uppercase;
                font-weight: 500;
                font-family: $heading-font;           
            }
            h2 {
                font-size: 40px;
                margin-bottom: 1em;
                @media screen and (max-width: 1199px) {
                    font-size: 35px;
                }
            }
            h3 {
                font-size: 32px;
                margin-bottom: .5em;
                @media screen and (max-width: 1199px) {
                    font-size: 28px;
                }
            }

            p {
                max-width: 500px;
                margin: 0 auto;
            }

            .stepimage {
                max-width: 500px;
                margin: 10px auto 50px;
                @media screen and (max-width: 460px){
                    max-width: calc(100% - 20px);
                }
            }
        }

        
    }

    .container.disclaimer-wrap { padding-bottom: 0; }
    .disclaimer {
        font-size: 14px;
        color: $light-yellow;
        line-height: 1.4;

        p {
            padding: 30px 80px 30px 0;

            @media screen and (max-width:460px) {
                padding: 30px;
            }

            a{
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}

//french version
.fr-CA{
    .main-wrapper .full-Vh .home-text-container .heading-text,
    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-the-secret,
    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        font-size: 41px;

        @media screen and (max-width: 460px) {
            font-size: 27px;
        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .unlock-win{
        @media screen and (max-width: 460px) {
            font-size: 27px;
        }
    }

    .main-wrapper .full-Vh .home-text-container .enter-btn-container button p{
        font-size: 22px;
        width: 100%;
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .chance-to-win{
        @media screen and (max-width: 460px) {
            font-size: 27px;
        }
    }

    .main-wrapper .full-Vh .home-text-container .heading-text .prize-money{
        @media screen and (max-width: 460px) {
            font-size: 68px;
        }
    }
    .main-wrapper .row {
        .coming-soon-text-container, .home-text-container {
            .heading-text {
                .prize-money {
                    font-size: 80px;
                    @media screen and (max-width: 1199px) {
                        font-size: 72px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 64px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 60px;
                    }
                }
                .plus-cash {
                    font-size: 40px;
                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }
                    @media screen and (max-width: 991px) {
                        font-size: 35px;
                    }
                    @media screen and (max-width: 460px) {
                        font-size: 30px;
                    }
                }
            }
        }
    }
}



