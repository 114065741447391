@import '../variables';

//PIN form
.pin-form__container{
    text-align: center;
    padding-top: 50px;

    .steps{
        color: white;
        font-size: 20px;

        .steps__progress{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;

            .step{
                width: 65px;
                height: 7px;
                background: white;
                padding: 0;
                border: 0;
                outline: none;

                &.one{
                    margin-right: 10px;
                    background: $yellow;
                }
            }
        }
    }

    .form__heading{
        font-size: 30px;
        color: white;
        text-transform: uppercase;
        font-family: $heading-font;
    }

    .form__description{
        color: white;
        font-size: 20px;
        max-width: 500px;
        margin: 20px auto;
        line-height: 1.3;
    }

    .pin-form {
        max-width: 300px;
        margin: 0 auto;
        padding-bottom: 100px;
        position: relative;

        form{
            position: relative;

            .pinFormPopupButton{
                position: absolute;    
                right: 10px;
                top: 3px;
                cursor: pointer;

                img{
                    display: flex;
                    max-width: 100%;
                }
            }
        }

        //popup for pin form
        .pinFormPopup{
            position: absolute;
            z-index: 99999;
            max-width: 320px;
            background: white;
            border-radius: 8px;
            color: $grey;
            border: 1px solid $yellow;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.21);

            opacity: 0;
            visibility: hidden;
            pointer-events: none;

            &.visible{
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            .pinFormPopup__closeButton {
                width: 25px;
                height: 25px;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;

                img{
                    max-width: 100%;
                }
            }
            
            .pinFormPopup--container{
                padding: 35px;
                padding-bottom: 15px;
                position: relative;

                &:after{
                    position: absolute;
                    content: "";
                    width: 30px;
                    height: 30px;
                    bottom: -16px;
                    right: 40px;
                    background: white;
                    transform: rotate(45deg);
                    border-bottom: 1px solid $yellow;
                    border-right: 1px solid $yellow;
                }
                .pinFormPupup__content {
                    text-align: left;

                    img {
                        max-width: 248px;
                        margin: 1em auto;
                    }
                }
            }
        }

        .MuiTextField-root{
            max-width: 355px;
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                font-size: 14px;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: 2px solid transparent;

                &.Mui-focused{
                    border: 2px solid $yellow;
                }

                .MuiFilledInput-input{
                    padding: 27px 12px 10px 0;
                    text-indent: 12px;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }

        .pin-form__submit-btn{
            background: transparent;
            display: inline-block;
            width: 269px;
            height: 76px;
            padding: 0;
            position: relative;
            border: none;
            outline: none;

            &[disabled] {
                opacity: .5;
            }

            img{
                position: absolute;
                top:0;
                left: 0;

                &.show{
                    display: block;
                }

                &.hide{
                    display: none;
                }
            }

            p{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
                font-size: 27px;
                color: white;
                font-family: $heading-font;
                text-shadow: 0 3px 6px rgba(0,0,0,0.4);
                width: 100%;
            }
        }
    }
}

//error message
.pin-not-found {
    position: fixed;
    bottom: 90px;
    padding: 30px;
    background: #dd0000;
    border-radius: 6px;
    box-shadow: 0 0 30px rgba(0,0,0,0.3);
    left: 50%;
    transform: translate(-50%, 30px);
    display: flex;

    @media screen and (max-width:600px) {
        bottom: 40px;
        width: calc(100% - 20px);
    }

    button{
        border: 2px solid #ffd478;
        background: $yellow;
        border-radius: 30px;
        color: #ff1606;
        margin-left: 20px;
        box-shadow: 0 0 20px rgba(0,0,0,.2);
    }
}

//info form
.info-form__container{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 100px;

    .steps{
        color: white;
        font-size: 20px;

        .steps__progress{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 0;
            
            .step{
                width: 65px;
                height: 7px;
                background: white;
                padding: 0;
                border: 0;
                outline: none;

                &.one{
                    margin-right: 10px;
                    background: white;
                }

                &.two{
                    background: $yellow;
                }
            }
        }
    }

    .form__heading{
        font-size: 30px;
        color: white;
        text-transform: uppercase;
        font-family: $heading-font;
    }

    .form__description{
        color: white;
        font-size: 20px;
        max-width: 500px;
        margin: 20px auto 40px auto;
        line-height: 1.3;
    }

    .info-form {
        max-width: 650px;
        margin: 0 auto;

        .MuiTextField-root{
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00 !important;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                font-size: 14px;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);

                    + div.MuiInputBase-root{
                        border: 2px solid $yellow;
                    }

                    ~ .MuiFormHelperText-root {
                        //display: none;
                    }
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: 2px solid transparent;

                &.Mui-focused{
                    border: 2px solid $yellow;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }

        .checkbox-input {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: left;

            .checkbox-input__label{
                font-family: $body-font;
                color: $yellow;
                font-size: 13px;
                line-height: 1;

                a{
                    color: inherit;
                    text-decoration: underline;
                }
            }

            + .infoForm__errorMessage{
                position: relative;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                font-size: 14px;
                line-height: 1.4;
                text-align: left;
                padding-left: 30px;
                margin: 3px 0;

                &:empty{
                    display: none;
                }

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }
        }

        .MuiFormControlLabel-root{
            margin-right: 0;

            //for checkbox focus 
            > span:not(.MuiTypography-body1){
                min-height: 42px;
                min-width: 42px;

                span:focus-within{
                    outline: 2px solid;
                }
            }

            .MuiFormControlLabel-label{
                font-size: 13px;
                text-align: left;
                color:$yellow;
                line-height: 1.3;
                font-family: $body-font;
            }

            + .infoForm__errorMessage{
                position: relative;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                font-size: 14px;
                line-height: 2;
                text-align: left;
                padding-left: 30px;

                &:empty{
                    display: none;
                }

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }
        }

        .info-form__legal{
            font-size: 13px;
            text-align: left;
            color: $yellow;
            line-height: 1;

            margin-bottom: 40px;
        }

        .info-form__submit-btn{
            background: transparent;
            display: inline-block;
            width: 269px;
            height: 76px;
            padding: 0;
            position: relative;
            border: none;
            outline: none;

            &[disabled] {
                opacity: .5;
            }

            img{
                position: absolute;
                top:0;
                left: 0;

                &.show{
                    display: block;
                }

                &.hide{
                    display: none;
                }
            }

            p{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
                font-size: 27px;
                color: white;
                font-family: $heading-font;
                text-shadow: 0 3px 6px rgba(0,0,0,0.4);
                // text-transform: uppercase;
            }
        }
    }
}