@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .page-heading{
        h1{
            font-family: $heading-font;
            color: #fff;
            text-align: center;
            padding: 60px 0;
            font-size: 50px;
            text-transform: uppercase;
        }
    }

    .page-body{
        &.rules {
            text-align: left;
            list-style-type: none;
            counter-reset: css-counter 0;

            h4 { counter-increment: css-counter 1; }
            h4:before { content: counter(css-counter) ". "; }
        }
        .text-block{
            padding-bottom: 40px;

            table { width: 100%; }
            table, th, td { border: 1px solid #fff; }
            td { padding: .5em; }

            h4, p, ul, table {
                padding: 0 0 20px;
            }
            ul {
                padding-left: 20px;
                li { list-style: disc;}
            }
            thead, b {
                font-weight: bold;
            }

            .page-block__heading, >  h4{
                font-weight: bold;
                u {
                    font-weight: normal;
                }
            }

            .page-block__body, > p {
                ol{
                    list-style: lower-alpha;
                    margin-left: 20px;
        
                    li{
                        margin: 10px 0;
                        padding-left: 5px;
                    }

                    &.numbered {
                        list-style: decimal;
                    }
                }

                ul{
                    list-style: disc;
                    margin-left: 20px;
    
                    li{
                        margin: 10px 0;
                    }
                }

                strong{
                    font-weight: bold;
                }

                a{
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    hyphens: none;
                }
            }
        }
    }
}