@import '../variables';

.main-wrapper{
    .prize-details__text-section{
        align-items: center;
        margin-bottom: 2em;

        h2{
            font-family: $heading-font;
            font-size: 30px;
            text-transform: uppercase;
            line-height: 1em;

            @media screen and (max-width:600px) {
                font-size: 30px !important;
            }
        }

        p{
            margin-top: 1em;
            @media screen and (max-width:600px) {
                font-size: 16px !important;
            }
        }

        &.grand-prize{
            @media screen and (max-width: 768px) {
                flex-direction: column-reverse;
            }
        }

        .img{
            @media screen and (max-width: 768px) {
                display: flex;
                justify-content: center;    
            }

            img{
                max-width: 100%;
            }
            @media screen and (min-width:600px) {
                &.left-overlap div {
                    margin-left: -40px;
                }
                &.right-overlap div {
                    margin-right: -40px;
                }
            }
        }

        ul{
            list-style-type: disc;
            margin-left: 20px;

            li{
                margin: 10px 0;
            }
        }
    }
}

.fr-CA{
    .main-wrapper .enter-contest-btn button p{
        font-size: 22px;
        width: 100%;
    }
}