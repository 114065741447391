@import '../variables';



//keys
.key-container{
    margin: 55px 0 10px;

    .remaining-keys{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 380px;
        min-height: 239px;
    
        .key{
            flex-basis: 18%;
            margin: 0 1%;
    
            img{
                display: flex;
                max-width: 100%;
            }
        }
    }
}

.enter-contest-btn.losing-page{
    padding-top:0 !important;
}

.caramilk-2-size{
    max-width: 100%;
    margin: 0px 0 30px 0;
}

.you-lose .packshot-group {
    margin: 80px auto 40px;
}
.you-lose .packshot-label {
    top: -30px;

    @media (min-width: 768px) { 
        top: -35px;
        left: -30px;  
    }
    @media (min-width: 992px) {
    
    }
}

.you-lose .social-container{
    display: flex;
    align-items: center;
    justify-content: center;

    .st-btn.st-first.st-last {
        display: block !important ;
        padding: 0 !important;
        min-width: 44px !important;
        min-height: 44px !important;
        max-width: 44px !important;
        max-height: 44px !important;
        width: 44px !important;
        height: 44px !important;
        border-radius: 44px !important;
        background: transparent url('../../images/header-twitter.png') !important;

        &:hover{
            top: 0 !important;
            opacity: 1 !important;
        }

        img {
            margin: 0 !important;
            height: 25px !important;
            width: 25px !important;
            display: none  !important;
        }
    }
}

