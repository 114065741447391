@import './variables';

body{
    font-family: sans-serif;
    background: $bg-brown;
}

a{
    &:focus{
        box-shadow: 0 0 2px 3px #78aeda, 0 0 2px #78aeda inset;
        border-radius: 2px;
    }
}

button{
    &:focus{
        box-shadow: 0 0 2px 3px #78aeda, 0 0 2px #78aeda inset;
        border-radius: 2px;
    }
}

.enter-btn{
    a{
        color: $light-yellow;
    }

    &:focus-visible{
        box-shadow: 0 0 2px 3px #78aeda, 0 0 2px #78aeda inset;
        border-radius: 2px;
    }
}

.main-wrapper{
    margin-top: 90px;
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 120px);
    .container { padding-bottom: 6em; }
}

#bg {
    //overflow:hidden;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    position: absolute;
    z-index:-99;
    //top:25px;
    // overflow: hidden;

    @media screen and (max-height: 700px) {
        min-height: 400px;
    }

    > *{
        // overflow: hidden;
    }
}
#canvas_bg {
    position: absolute;
}
.floor, #fade, #tsparticles {
    position: fixed;
}
.floor { bottom: 0; min-width: 100%; 
    &#bokeh1 {
        animation: bokehglow 2s infinite alternate;
    }
    &#bokeh2 {
        animation: bokehglow 3s infinite alternate;
    }
}
#fade { top: 0; min-width: 100%; }
#tsparticles { top:0; }
#glow_wrapper_wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow:hidden;
    #glow_wrapper { 
        margin: 0 auto; position: relative; height: 100%; 
        @media (min-width: 768px) {
            max-width: 720px; 
        }
        @media (min-width: 992px) {
            max-width: 960px;
        }
        @media (min-width: 1200px) {
            max-width: 1140px; 
        }
    }
}

#glow{
    position: fixed;
    left: 50%; margin-left: -274px; margin-top:-274px; top: 120%;
    transform-origin: 274px 274px;
    transform: scale(1.5);

    .glowbg {
        position: absolute;
        top: 0;
        left: 0;

        &.slowspin {
            animation: spinglow 60s infinite linear;
        }
        &.medspin {
            animation: spinglow 50s infinite linear;
        }
        &.quickspin {
            animation: spinglow 40s infinite linear;
        }
        &.pulse {
            animation: pulseglow 3s infinite alternate;
        }

        &#twinkle_wrapper {
            width: 548px; height: 548px;
            
            #twinkle1 { left: 99px; top: 228px; }
            #twinkle2 { left: 419px; top: 109px; }
            #twinkle3 { left: 339px; top: 235px; }
            #twinkle4 { left: 207px; top: 363px; }
            .twinkle {
                position: absolute;
                animation: spinglow 10s infinite linear;
            }
        }
    }
}

#glow.homepage { 
    position: absolute;
    left: 50%; margin-left: -274px; margin-top:-274px; top: 320px; 
    transform: scale(1);
    // animation: spinglow 60s infinite linear;

    // .fr-CA & {
    //     top: 440px;

    //     @media (min-width: 768px) {
    //         top: 370px;
    //     }
    // }

    @media (min-width: 768px) {
        left: 73%; top: 230px; 
    }
    @media (min-width: 992px) {
        // top: calc(50% - 150px);
    }
    // @media (min-width: 1200px) {
    //     top: calc(50% - 150px); 
    // }

    @media screen and (max-height: 700px) {
        //  top: 200px;
    }
}

@keyframes spinglow {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
}
@keyframes pulseglow {
    from{opacity:1; transform:scale(1)}
    to{opacity:.2;transform:scale(1.2)}
}
@keyframes bokehglow {
    from{opacity:0; transform:scale(1)}
    to{opacity:1;transform:scale(1.03)}
}

#tsparticles{
    width: 100%;
    height: 100%;
}

.enter-contest-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    button{
        background: transparent;
        display: inline-block;
        width: 269px;
        height: 76px;
        padding: 0;
        position: relative;
        border: none;

        img{
            position: absolute;
            top:0;
            left: 0;

            &.hover{
                display: none;
            }
        }

        p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
            font-size: 24px;
            color: white;
            font-family: $heading-font;
            text-shadow: 0 3px 6px rgba(0,0,0,0.4);
            white-space: nowrap;
            text-align: center !important;
            // text-transform: uppercase;
        }
    }
    a:hover {
        button img.hover {
            display:block;
        }
    }
}
.enter-contest-btn + .enter-contest-btn {
    margin-top: 1em;
}
.winner-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button{
        background: transparent;
        display: inline-block;
        width: 256px;
        height: 58px;
        padding: 0;
        position: relative;
        border: 2px solid $yellow;
        border-radius: 30px;


        p{
            font-size: 24px;
            color: white;
            font-family: $heading-font;
            text-shadow: 0 3px 6px rgba(0,0,0,0.4);
            // text-transform: uppercase;
        }
    }
    a:hover {
        button {
            background: $yellow;
            p {
                text-decoration: none;
            }
        }
    }
}