@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .key-container{
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 75%;
        }
    }

    .you-win{
        text-align: center;

        h1{
            font-family: $heading-font;
            font-size: 40px;
            text-transform: uppercase;
            line-height: 1.2;
            margin-bottom: 15px;
        }

        h3{
            font-size: 30px;
            font-family: $heading-font;
            line-height: 1.2;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        p{
            margin-bottom: 30px;
            font-size: 20px;
            text-align: left;

            a{
                color: $light-yellow;
                text-decoration: underline;
            }
            &.legal {
                font-size: .75em;
            }
        }

        small{
            margin-bottom: 20px;
            display: inline-block;
            color: #f7d587;
            font-size: 10px;
        }

        span{
            font-size: 20px;
        }

        .social-container{
            margin-bottom: 20px;
            
            img{
                margin: 0 10px;
                max-width: 44px;
            }
        }

        .download-btn{
            margin-bottom: 135px;
        }


        .MuiTextField-root{
            max-width: 355px;
            width: 100%;
            margin-bottom: 25px;

            &.error{
                .MuiInputBase-root{
                    border: 2px solid #d00;
                }
            }

            .MuiFormHelperText-root{
                display: block;
                position: relative;
                margin: 0;
                padding: 4px;
                background: #dd0000;
                color: white;
                font-family: $body-font;
                font-size: 14px;
                margin-top: -6px;
                z-index: 6;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
                padding-left: 30px;

                &:before{
                    position: absolute;
                    content: "";
                    top: 50%;
                    left: 7px;
                    width: 16px;
                    height: 16px;
                    background: url(../../images/error-symbol.png) no-repeat center center;
                    transform: translateY(-50%);
                }
            }

            label{
                transform: translate(12px, 22px) scale(1);

                &.MuiInputLabel-shrink{
                    transform: translate(12px, 8px) scale(.75);
                }

                &.Mui-focused{
                    color: rgb(122, 122, 122);
                }
            }

            .MuiInputBase-root{
                background: white;
                border-radius: 8px;
                border: 2px solid transparent;

                &.Mui-focused{
                    border: 2px solid $yellow;
                }

                .MuiFilledInput-input{
                    padding: 27px 12px 10px 0;
                    text-indent: 12px;
                }
            }

            .MuiFilledInput-underline{
                &:after{
                    display: none;
                }

                &:before{
                    display: none;
                }
            }
        }

        .pin-form__submit-btn{
            background: transparent;
            display: inline-block;
            width: 269px;
            height: 76px;
            padding: 0;
            position: relative;
            border: none;
            outline: none;

            &[disabled] {
                opacity: .5;
            }

            img{
                position: absolute;
                top:0;
                left: 0;

                &.show{
                    display: block;
                }

                &.hide{
                    display: none;
                }
            }

            p{
                position: absolute;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
                font-size: 27px;
                color: white;
                font-family: $heading-font;
                text-shadow: 0 3px 6px rgba(0,0,0,0.4);
                width: 100%;
            }
        }
    }
}