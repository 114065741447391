@import '../../styles/variables';

.main-header {
    background: $chocolate;
    position: fixed;
    width: 100%;
    border-bottom: 2px solid $yellow;
    top:0;
    z-index: 99999;
    height: 90px;
    display: flex;
    align-items: center;

    .container{
        .row{

            @media screen and (max-width: 768px) {
                flex-wrap: nowrap;
            }

            *{
                display: flex;
                align-items: center;
            }

            .lang{
                color: white;
            }

            .logo{
                align-items:center;
                justify-content: center;
                font-size: 23px;
                color: white;
                text-transform: uppercase;
                padding: 20px 0;
                box-sizing: border-box;

                a{
                    color: inherit;
                    justify-content: center;

                    &:hover{
                        text-decoration: none;
                    }

                    img{
                        max-width: 162px;

                        @media screen and (max-width: 460px) {
                            max-width: 130px;
                        }
                    }
                }
            }

            .menu{
                justify-content: flex-end;

                button{
                    background: transparent;
                    border: none;
                    color: white;
                    outline: none;

                    @media screen and (max-width: 460px) {
                        max-width: 70px;
                    }

                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.nav-wrapper {
    position: fixed;
    background: rgba(37,16,4, 0.87);
    width: 100%;
    height: calc(100% - 88px);
    transition: all .3s ease;
    z-index: 9999;
    max-width: 100%;

    &.nav-closed{
        transform: translate3d(0, -100%, 0);
        opacity: 0;
    }

    &.nav-opened{
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }

    .nav-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        @media screen and (max-height: 640px) {
            align-items: flex-start;
        }

        ul.nav-list{

            @media screen and (max-height: 640px) {
                margin-top: 30px;
            }

            .nav-list__item{
                list-style: none;
                text-align: center;
                margin-bottom: 20px;
    
                &.header-key{
                    position: absolute;
                    bottom: 0;
                    right:0;
    
                    @media screen and (max-width: 768px) {
                        position: relative;
                    }
                }
        
                a{
                    font-size: 33px;
                    font-family: $heading-font;
                    // text-transform: uppercase;
                    color: white;
                    text-align: center;

                    @media screen and (max-width:600px) {
                        max-width: 77px;
                    }
        
                    &:hover{
                        text-decoration: none;
                        color: $yellow;
                    }
                }
        
                &.nav-social-icons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 60px;

                    a{
                        margin: 0 15px;
                        max-width: 44px;
                    }

                    button{
                        padding: 0;
                        
                        img{
                            //padding: 0 10px;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}