@import '../variables';

.main-wrapper{
    font-family: $body-font;
    color: white;
    line-height: 1.4;

    .page-heading{
        h1{
            font-family: $heading-font;
            color: #fff;
            text-align: center;
            padding: 60px 0;
            font-size: 50px;
            text-transform: uppercase;
        }
    }

    .page-body.faq {
        text-align: left;

        > p{
            padding: 0 0 40px;
        }

        a{
            color: inherit;
            text-decoration: underline;
        }

        .qa{
            padding: 0 0 40px;

            .q{
                font-size: 20px;
                font-weight: 800;
                margin-bottom: 10px;
            }

            ul{
                padding-left: 20px;
                padding-top: 20px;
                list-style-type: disc;
                list-style-position: outside;
            }

            .a { padding: 0;}
        }
    }
}