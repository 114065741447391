@import '../variables';

.main-wrapper {
    margin-top: 90px;
    position: relative;
    z-index: 1;

    // background: url(../../images/coming-soon-bg.jpg);

    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: calc(100vh - 186px); // 100 height - (header + footer)

    @media screen and (max-width: 460px) {

        // background: url(../../images/coming-soon-bg-mobile.jpg);
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 90px;
    }
    _:-ms-fullscreen, :root .full-Vh { height: calc(100vh - 300px); } // IE 11?
    .full-Vh {
        min-height: calc(100vh - 65px); // 100 height - (header + footer + disclaimer)
        
        display: flex;
        align-items: center;
    }
    .row {
        .coming-soon-text-container {
            color: #fff;
            margin-top: 100px;

            @media screen and (max-width:460px) {
                margin-top: 30px;
            }

            .heading-text {
                text-transform: uppercase;
                font-size: 50px;
                font-family: $heading-font;
                // text-shadow: -4px -4px 0 $chocolate, 4px -4px 0 $chocolate, -4px 4px 0 $chocolate, 4px 4px 0 $chocolate;
                text-stroke: 1px $chocolate;
                -webkit-text-stroke: 1px $chocolate;
                text-align: center;
                line-height: 1;


                .unlock-the-secret {
                    font-size: 40px;
                    line-height: 1.2;
                    // font-weight: 500;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 30px;
                    }
                }

                .chance-to-win {
                    font-size: 40px;
                    line-height: 1.2;
                    // font-weight: 500;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 35px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 30px;
                    }

                    em{
                        font-style: italic;
                    }
                }

                .unlock-win{
                    font-size: 40px;
                    line-height: 1.2;
                    font-weight: 500;

                    @media screen and (max-width: 1199px) {
                        font-size: 35px;
                    }
                }

                .prize-money {
                    font-size: 120px;
                    line-height: 1.1;

                    text-stroke: 3px $chocolate;
                    -webkit-text-stroke: 3px $chocolate;

                    sup {
                        font-size: .5em;
                        vertical-align: .8em;
                        top: 0;
                    }
                    small {
                        font-size: .7em;
                    }

                    @media screen and (max-width: 1199px) {
                        font-size: 102px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 80px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 70px;
                    }

                    

                    + .prize-money {
                        font-size: 80px;
                        @media screen and (max-width: 1199px) {
                            font-size: 72px;
                        }
    
                        @media screen and (max-width: 991px) {
                            font-size: 64px;
                        }
    
                        @media screen and (max-width: 460px) {
                            font-size: 60px;
                        }
                    }
                }

                .plus-cash {
                    font-size: 55px;
                    line-height: 1.2;

                    @media screen and (max-width: 1199px) {
                        font-size: 44px;
                    }

                    @media screen and (max-width: 991px) {
                        font-size: 40px;
                    }

                    @media screen and (max-width: 460px) {
                        font-size: 35px;
                    }
                }
            }

            .coming-soon {
                // font-family: $heading-font;
                font-size: 24px;
                text-align: center;
                margin: 0 0 40px;
                color: $white;
                // text-transform: uppercase;
                line-height: 1.2;

                em {
                    color: $yellow;
                }

                @media screen and (min-width: 768px) {
                    // font-size: 25px;
                    margin: 30px 0;
                }
            }

            .enter-contest-btn a button {
                margin-top: 10px;
                width: 326px;
            }

            
        }
        .coming-soon-key-container {
            @media (min-width: 768px) { 
                padding-top: 130px;
            }
        }
    }

    .container.peek {
        margin-top: 5vh;
        
        @media screen and (min-width: 768px) and (orientation:portrait) {
            margin-top: -10vh;
        }
        @media screen and (min-width: 992px) {
            margin-top: -10vh;
        }
    }

    .packshot-group {
        position: relative;
    }
    .packshot {
        max-width: 80%;
    }
    .packshot-label {
        background-image: url(../../images/banner.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        font-size: 1.2em;
        line-height: 1.2em;
        text-align: left;
        padding: .5em 1em 1em 2em;
        position: absolute;
        left: -20px;
        top: -40px;
        transform: rotate(-12deg);
        font-family: $heading-font;
        font-weight: 500;

        @media (min-width: 768px) { 
            transform: rotate(-22deg);
            // left: -40px;
            top: -20px;    
        }
        @media (min-width: 992px) {
            font-size: 1.5em;
            
            top: -30px;
            // left: 50%;
            // margin-left: -330px;
        }
        @media (min-width: 1200px) {
            // left: 0px;
            // margin-left: -380px;
        }
    }

    
    .container.disclaimer-wrap { padding-bottom: 0; }
    .disclaimer {
        font-size: 14px;
        color: $light-yellow;
        line-height: 1.4;

        p {
            padding: 30px 80px 30px 0;

            @media screen and (max-width:460px) {
                padding: 30px;
            }
        }
    }
}
